define("ember-modifier/-private/class/modifier", ["exports", "@ember/application", "@ember/modifier", "ember-modifier/-private/class/modifier-manager"], function (_exports, _application, _modifier, _modifierManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DESTROYING = _exports.DESTROYED = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const DESTROYING = _exports.DESTROYING = Symbol('destroying');
  const DESTROYED = _exports.DESTROYED = Symbol('destroyed');
  class ClassBasedModifier {
    constructor(owner, args) {
      _defineProperty(this, DESTROYING, false);
      _defineProperty(this, DESTROYED, false);
      (0, _application.setOwner)(this, owner);
      this.element = null;
      this.args = args;
    }
    didReceiveArguments() {}
    didUpdateArguments() {}
    didInstall() {}
    willRemove() {}
    willDestroy() {}
    get isDestroying() {
      return this[DESTROYING];
    }
    get isDestroyed() {
      return this[DESTROYED];
    }
  }
  _exports.default = ClassBasedModifier;
  (0, _modifier.setModifierManager)(() => _modifierManager.default, ClassBasedModifier);
});